html {
    background-image: url("./tristan_background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}

body {
    color: white;
    font-family: sans-serif;
}

.site {
    width: 100%;
}

.app {
    width: 100%;
}

.panel {
    text-align: center;
    max-width: 550px;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.footer {
    display: grid;
    grid-template-columns: auto auto;
    height: 150px;
    background-color: gray;
    position:fixed;
    bottom:0;
    left:0;
    width: 100%;
}
.footer-text {
    color: whitesmoke;
    font-weight: bolder;
    text-shadow: 2 2 2;
    width: 100%;
    font-style: italic;
}
.footer-my-text {
    text-align: center;
    font-weight: bolder;
}